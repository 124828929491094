<template>
  <div>
    <p v-if="!countButtons">
      Для вашего удобства <b>документ сформирован</b>, теперь,
      <strong
        >необходимо его скачать и направить нам по почте info@onlinelogic.ru скан подписанного
        экземпляра</strong
      >
    </p>

    <ServicesDescription>
      <template #body>
        <div v-html="document">{{ document }}</div>
      </template>
      <template #footer>
        <b-row style="width: 100%">
          <b-col class="dflex align-center lg-align-between">
            <div v-for="(btn, index) in buttons" :key="btn.text" :class="$style.btnWrap">
              <BaseButton v-if="index < 2" @click.stop.prevent="btn.action">
                <template #icon>
                  <b-icon :icon="btn.icon" :scale="btn.scale"></b-icon>
                </template>
                <template #text>
                  {{ btn.text }}
                </template>
              </BaseButton>
              <BaseButton v-else-if="index === 2 && countButtons" @click.stop.prevent="btn.action">
                <template #icon>
                  <b-icon :icon="btn.icon" :scale="btn.scale"></b-icon>
                </template>
                <template #text>
                  {{ btn.text }}
                </template>
              </BaseButton>
            </div>
          </b-col>
        </b-row>
      </template>
    </ServicesDescription>
  </div>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import { mapGetters, mapMutations } from 'vuex';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import { sendCustomerRequest, downloadFile } from '@/api/common';
import {
  catchFunction,
  processResponse,
  createElementForDownload
} from '@/additional_helpers/helpers';
import URL from '@/basUrl';

export default {
  props: {
    document: {
      type: String,
      require: true
    }
  },

  data() {
    return {
      buttons: [
        {
          icon: 'pencil-fill',
          scale: '0.8',
          text: 'Редактировать',
          action: this.editData
        },
        {
          icon: 'download',
          scale: '0.8',
          text: 'Скачать сформированную заявку в формате PDF',
          action: this.downloadPdf
        },
        {
          icon: 'arrow-bar-up',
          scale: '0.8',
          text: 'Отправить',
          action: this.createStatement
        }
      ]
    };
  },

  components: {
    ServicesDescription,
    BaseButton
  },

  computed: {
    ...mapGetters({ htmlData: 'htmlData' }),

    countButtons() {
      return JSON.parse(sessionStorage.getItem('canSend'));
    }
  },

  methods: {
    ...mapMutations({ MAKE_ACTIVE: 'MAKE_ACTIVE' }),

    editData() {
      this.MAKE_ACTIVE(false);
    },

    createStatement() {
      sendCustomerRequest(URL.STATEMENT_SEND, this.htmlData)
        .then((result) => {
          processResponse(result);
          if (result.status === 200) {
            setTimeout(() => {
              this.MAKE_ACTIVE(false);
            }, 1000);
          }
        })
        .catch((e) => catchFunction(e));
    },

    downloadPdf() {
      downloadFile(URL.STATEMENT_PDF, this.htmlData)
        .then((bytes) => createElementForDownload(bytes.text, 'statement-Online-Logic.pdf'))
        .catch((e) => catchFunction(e));
    }
  }
};
</script>

<style>
.bonus > ul {
  padding-inline-start: revert;
}

.bonus > ul > li {
  list-style-type: disc;
  margin-bottom: 0.375rem;
}
</style>

<style module>
.btnWrap {
  padding-top: 1rem;
}

.btnWrap:nth-child(2) {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 992px) {
  .btnWrap {
    padding-top: 0;
  }

  .btnWrap:nth-child(2) {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
